@import 'functions';
@import 'variables';
@import 'animations';

@mixin limiter() {
  width: rem(1600);
  max-width: 100%;
  margin: 0 auto;
  @include relative();
  padding-left: 16px;
  padding-right: 16px;
}


@mixin hover() {
  &:hover,
  &:active,
  &:focus {
    @content;
  }
}

$breakpoints: (
  s: 330px,
  phone: 480px,
  xs: 769px,
  mobile: 1023px,
  desktop: 1024px,
  xl: 1200px,
  middle: 1600px
);
@mixin zindex($layer) {
  z-index: z($layer);
}
@mixin breakpoint($point) {

  @if $point == s {
    @media only screen and (max-width: map-get($breakpoints, 's')) {
      @content;
    }
  }
  @if $point == phone {
    @media only screen and (max-width: map-get($breakpoints, 'phone')) {
      @content;
    }
  }
  @else if $point == xs {
    @media only screen and (max-width: map-get($breakpoints, 'xs')) {
      @content;
    }
  }
  @else if $point == mobile {
    @media only screen and (max-width: map-get($breakpoints, 'mobile')) {
      @content;
    }
  }
  @else if $point == desktop {
    @media only screen and (min-width: map-get($breakpoints, 'desktop')) {
      @content;
    }
  }
  @else if $point == xl {
    @media only screen and (max-width: map-get($breakpoints, 'xl')) {
      @content;
    }
  }
  @else if $point == middle {
    @media only screen and (max-width: map-get($breakpoints, 'middle')) {
      @content;
    }
  }
}
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}
// Padding shorthand version
@mixin padding($padding) {
  padding: $padding;
}

// Padding longhand version
@mixin padding-all($top: null, $right: null, $bottom: null, $left: null) {
  padding-top: $top;
  padding-right: $right;
  padding-bottom: $bottom;
  padding-left: $left;
}
// Margin shorthand version
@mixin margin($margin) {
  margin: $margin;
}

// Margin longhand version
@mixin margin-all($top: null, $right: null, $bottom: null, $left: null) {
  margin-top: $top;
  margin-right: $right;
  margin-bottom: $bottom;
  margin-left: $left;
}
// Positions

@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}
@mixin absolute($args...) {
  @include position(absolute, $args...);
}
@mixin relative($args...) {
  @include position(relative, $args...);
}
@mixin fixed($args...) {
  @include position(fixed, $args...);
}
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

// =============================================================================
// String Replace
// =============================================================================

@function str-replace($string, $search, $replace: "") {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}

// =============================================================================
// Font Face
// =============================================================================

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
	$src: null;

	$extmods: (
		eot: "?",
		svg: "#" + str-replace($name, " ", "_")
	);

	$formats: (
		otf: "opentype",
		ttf: "truetype"
	);

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
	}

	@font-face {
		font-family: quote($name);
		font-style: $style;
		font-weight: $weight;
		src: $src;
	}
}

// скрыть скролл
@mixin hideScrollBar() {
  scrollbar-width: none; /* Firefox 64 */
  -ms-overflow-style: none; /* IE 11 */
  &::-webkit-scrollbar { /** Webkit */
    display: none;
  }
}
