@function HexToRGB($hex) {
  @return red($hex), green($hex), blue($hex);
}
@function cl($name) {
  @return var(--color-#{unquote($name)});
}
$colors : (
  "bg":       #0D1C21,
  "bg-secondary": #1E2531,
  "primary":  #14B3E7,
  "text":     #FFFFFF,
  "bg-white":    #fff,
  "link-hover":     #093581,
  "grey":     #292a37,
  "primary-pink":    #E3357E,
  'bg-buttons': #a3a3c0,
  'error': #ff0000
);
:root{
  @each $key, $value in $colors {
    --color-#{$key} : #{$value};
    --color-#{$key}-rgb : #{HexToRGB($value)};
  }
}
