@import 'theme_default';
@import 'mixins';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;800&display=swap');
// @import 'themes';

// @import '~@nebular/theme/styles/globals';

// @include nb-install() {
//   @include nb-theme-global();
// };
/* You can add global styles to this file, and also import other style files */

@include breakpoint(mobile) {
  $header-menu-font-size: 14;
  $default-left-offset-desktop: 16;
}

html {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  background: var(--color-bg);
  color: var(--color-text);
  height: fit-content;
  scrollbar-width: none; /* Firefox 64 */
  -ms-overflow-style: none; /* IE 11 */
  // &::-webkit-scrollbar { /** Webkit */
  //   display: none;
  // }


  //font-size: calc((16 * 100 / 1920) * 1vw) !important;
  font-size: 16px;

  @include breakpoint(mobile) {
    font-size: calc((13 * 100 / 1024) * 1vw) !important;
  }

  // @include breakpoint(xs) {
  //   font-size: calc((13 * 100 / 320) * 1vw) !important;
  // }

}
// * {
//   background: rgba(0,255,0,0.2);
// }
body {
  height: fit-content;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
  &.modal-open {
    overflow: hidden;
  }
}
body,
html {
  min-height: 100%;
  margin: 0;
  padding: 0;
}


.ripple {
  @include relative();
  overflow: hidden;
  &__inner {
    @include absolute();
    @include border-radius(50%);
  }
}

/* slightly transparent fallback */
.backdrop-blur {
  background-color: rgba(var(--color-bg-rgb), 0.8);
  @supports ((-webkit-backdrop-filter: blur(30px)) or (backdrop-filter: blur(30px))) {
    background-color: rgba(var(--color-bg-rgb), 0.2);
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
  }
}

// @supports (mask-image: paint(smooth-corners)) {
//   .el.is-loaded {
//     border-radius: 0;
//     mask-image: paint(smooth-corners);
//     --smooth-corners: 5;
//   }
// }
input:-webkit-autofill{
  -webkit-text-fill-color: var(--color-text) !important;
}
/* Change the white to any color */
// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// input:-webkit-autofill:active {
//     transition: background-color 5000s ease-in-out 0s;
// }
input:-webkit-autofill {
  background-clip: text;
  -webkit-background-clip: text;
}
a, button {
  -webkit-tap-highlight-color: transparent !important;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

h1 {
  color: var(--color-text);
  font-size: rem(36);
  line-height: rem(72);
  margin: 0 0 rem(32) 0;
  transition: 200ms ease;

  @media (hover: hover) {
    &:hover {
      color: var(--color-primary);
    }
  }

  a {
    text-decoration: none;
    color: inherit;
  }
}

h2 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-size: rem(36);
  line-height: rem(56);
  color: var(--color-text);
  margin: 0 0 rem(8);

  @include breakpoint(xs) {
    font-size: calc(rem(36)*1.5);
    margin-block: 0 rem(16);
  }

  @include breakpoint(phone) {
    font-size: calc(rem(36)*2);
    line-height: calc(rem(56)*2);
  }


  a {
    display: block;
    text-decoration: none;
    color: inherit;
    transition: 200ms ease;

    @media (hover: hover) {
      &:hover {
        color: var(--color-primary);
      }
    }
  }
}

h3 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-size: rem(24);
  line-height: rem(28);
  color: var(--color-primary);
  transition: 200ms ease;
  margin: 0 0 rem(16);

  @include breakpoint(xs) {
    font-size: calc(rem(24)*1.5);
    line-height: calc(rem(28)*1.5);
  }

  @include breakpoint(phone) {
    font-size: calc(rem(24)*2.6);
    line-height: calc(rem(28)*3);
  }

  a {
    text-decoration: none;
    color: inherit;

    @media (hover: hover) {
      &:hover {
        color: var(--color-link-hover);
      }
    }
  }
}

h4 {
  font-weight: 500;
  font-size: rem(12);
  line-height: rem(16);
  letter-spacing: 0.12em;
  text-transform: uppercase;
  margin: 0 0 rem(20);

  @include breakpoint(xs) {
    font-size: rem(32);
    line-height: rem(48);
  }
}
// * {
  // background: rgba(0, 255, 0, 0.1) !important;
// }
.title-divider {
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.4);
  margin: 0 0 32px 0;
}
ymaps {
  border-radius: 0 !important;
}
article {
  display: block;
  box-sizing: border-box;
  @include limiter();
}

.htmlOutput {
  a {
    text-decoration: none;
    color: var(--color-primary);
    &.btn {
      padding: 8px 20px;
      text-decoration: none;
      border-radius: 40px;
      font-weight: 800;
      text-transform: uppercase;
      font-size: inherit;
      letter-spacing: 0.12em;
      display: inline-flex;
      color: var(--color-text);
      background: var(--color-primary);
      &.active {
        text-decoration: none;
      }
      @media (hover: hover) {
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
.ripple {
  @include relative();
  overflow: hidden;
  &__inner {
    @include absolute();
    @include border-radius(50%);
  }
}
